<template>
    <footer class="footer">
        <a class="footer__amway-logo" href="https://amway.ru">
            <svg width="70" height="24" viewBox="0 0 70 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 24C0 24 40.1653 13.9979 69.9153 21.0396L70 20.7234C70 20.7234 44.0323 12.9345 0.508065 22.4192L0 24Z" fill="#E40040"/>
                <path d="M19.4313 4.28872V5.9571C19.604 5.48581 19.8945 5.06922 20.2736 4.74896C20.8742 4.26555 21.6155 4.00224 22.3792 4.00107C22.7807 3.99768 23.1797 4.06581 23.5584 4.20242C24.398 4.51915 25.043 5.22258 25.2991 6.10092C25.9217 4.81052 27.207 3.99583 28.612 4.00107C29.5296 3.97799 30.4185 4.32986 31.0826 4.97908C32.1214 6.01463 32.1775 7.16523 32.1775 10.013V16.5714H29.1173V10.7609C29.1173 8.80484 29.0893 8.40213 28.7523 7.85559C28.4962 7.39529 28.0047 7.12672 27.489 7.16523C26.9571 7.15413 26.4577 7.42631 26.1694 7.88436C25.8325 8.48843 25.8325 9.49521 25.8325 10.502V16.5714H22.7442V10.3869C22.7442 9.06373 22.7442 8.45966 22.4354 7.91313C22.1706 7.45487 21.6917 7.17135 21.172 7.16523C20.8972 7.16015 20.626 7.22961 20.3859 7.36659C19.4875 7.88436 19.4594 8.77608 19.4594 11.1061V16.5427H16.3711V4.28872H19.4313Z" fill="#034580"/>
                <path d="M44.2693 4.57143H47.4192L44.2693 16.5714H41.3748L39.5302 8.47775L37.714 16.5714H34.7627L31.6128 4.57143H34.7627L36.437 12.0749L38.1397 4.57143H40.8923L42.595 12.0749L44.2693 4.57143Z" fill="#034580"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M57.0163 16.315H54.0618V14.9936C53.4992 16.0131 52.4201 16.6223 51.2729 16.5681C48.7326 16.5681 46.855 14.2625 46.855 10.4949C46.855 5.77134 49.4781 4 51.4938 4C52.6535 4 53.5924 4.6748 54.117 5.82757V4.28117H57.0163V16.315ZM53.1229 13.1098C53.758 12.3225 53.8961 11.2822 53.8961 10.1856C53.8961 8.63923 53.5371 7.82385 53.1229 7.4021C52.8183 7.03906 52.3768 6.8245 51.908 6.81165C51.3024 6.84164 50.7478 7.16586 50.417 7.68326C49.8923 8.44241 49.7819 9.39837 49.7819 10.3262C49.7592 11.0259 49.8718 11.7233 50.1132 12.3787C50.5274 13.419 51.1901 13.7846 51.7976 13.7846C52.3145 13.7656 52.7979 13.5194 53.1229 13.1098Z" fill="#034580"/>
                <path d="M64.0873 4.6001H67.1774L63.781 16.7578C63.5305 17.618 63.1407 18.9944 62.5283 19.6825C61.9715 20.2847 61.3033 20.5714 58.8257 20.5714C58.5557 20.5686 58.2861 20.5495 58.0183 20.5141V17.7901H58.8257C59.1041 17.7901 59.8 17.7901 60.1063 17.4747C60.4061 17.1398 60.5661 16.697 60.5517 16.2417C60.5281 15.888 60.453 15.5401 60.329 15.2094L57.0161 4.57143H60.329L62.2777 12.8295L64.0873 4.6001Z" fill="#034580"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.2583 16.5714L7.50433 0H11.4604L16.9357 16.5714H13.037L12.0337 13.0468H7.103L6.12832 16.5714H2.2583ZM11.2024 10.0338L9.51101 3.63832L7.90567 10.0338H11.2024Z" fill="#034580"/>
            </svg>
        </a>
        <div class="footer__copyright">
            © 2011-2017 Amway Europe. All rights reserved.
        </div>
        <a href="https://www.amwayglobal.com" class="footer__link">
            www.amwayglobal.com
        </a>
    </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped lang="scss">
    .footer {
        padding: 14px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #EDEDED;

        &__copyright {
            font-family: "Source Sans Pro", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #56565A;
        }

        &__link {
            font-family: "Source Sans Pro", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #56565A;
            text-decoration: none;
        }
    }
</style>