<template>
    <header class="header">
        <a href="/" class="header__logo d-none d-sm-block">
            <svg width="124" height="43" viewBox="0 0 124 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 42.5C0 42.5 71.15 25.1 123.85 37.35L124 36.8C124 36.8 78 23.25 0.9 39.75L0 42.5Z" fill="white"/>
                <path d="M34.0999 8.29999V11.2C34.3499 10.6 34.6999 9.84999 35.5999 9.09999C36.5499 8.29999 37.9999 7.79999 39.3499 7.79999C40.0499 7.79999 40.7499 7.89999 41.4499 8.14999C43.6499 8.99999 44.2999 10.7 44.5499 11.45C46.2499 8.29999 48.9999 7.79999 50.4499 7.79999C52.2499 7.79999 53.7999 8.44999 54.8499 9.49999C56.6999 11.3 56.7999 13.3 56.7999 18.25V29.65H51.3499V19.55C51.3499 16.15 51.2999 15.45 50.6999 14.5C50.0999 13.5 49.2499 13.3 48.4499 13.3C46.9499 13.3 46.1999 14.35 46.0999 14.55C45.4999 15.6 45.4999 17.35 45.4999 19.1V29.65H39.9999V18.9C39.9999 16.6 39.9999 15.55 39.4499 14.6C39.0499 13.9 38.1999 13.3 37.1999 13.3C36.6999 13.3 36.2499 13.4 35.7999 13.65C34.1999 14.55 34.1499 16.1 34.1499 20.15V29.6H28.6499V8.29999H34.0999Z" fill="white"/>
                <path d="M78.55 8.29999H84.1L78.55 29.65H73.45L70.2 15.25L67 29.65H61.8L56.25 8.29999H61.8L64.75 21.65L67.75 8.29999H72.6L75.6 21.65L78.55 8.29999Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M101.25 29.65H95.9001V27.3C95.2501 28.3 94.0001 30.1 90.8501 30.1C86.2501 30.1 82.8501 26 82.8501 19.3C82.8501 10.9 87.6001 7.75 91.2501 7.75C93.3501 7.75 95.0501 8.95 96.0001 11V8.25H101.25V29.65ZM94.2001 23.95C95.3501 22.55 95.6001 20.7 95.6001 18.75C95.6001 16 94.9501 14.55 94.2001 13.8C93.6501 13.15 92.8001 12.75 92.0001 12.75C91.0001 12.75 89.9501 13.35 89.3001 14.3C88.3501 15.65 88.1501 17.35 88.1501 19C88.1501 20.3 88.3001 21.6 88.7501 22.65C89.5001 24.5 90.7001 25.15 91.8001 25.15C92.1501 25.15 93.2501 25.05 94.2001 23.95Z" fill="white"/>
                <path d="M113.9 8.3H119.45L113.35 29.5C112.9 31 112.2 33.4 111.1 34.6C110.1 35.65 108.9 36.15 104.45 36.15C103.8 36.15 103.55 36.1 103 36.05V31.3H104.45C104.95 31.3 106.2 31.3 106.75 30.75C107.2 30.4 107.55 29.45 107.55 28.6C107.55 28.15 107.35 27.3 107.15 26.8L101.2 8.25H107.15L110.65 22.65L113.9 8.3Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.7998 29.65L12.9498 0.5H19.8498L29.3998 29.65H22.5998L20.8498 23.45H12.2498L10.5498 29.65H3.7998ZM19.3998 18.15L16.4498 6.9L13.6498 18.15H19.3998Z" fill="white"/>
            </svg>
        </a>
        <a href="/" class="header__logo d-sm-none">
            <svg width="27" height="31" viewBox="0 0 27 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 29.9351C0 29.9351 15.3104 26.1785 26.6395 28.8251L26.8205 28.1923C26.8205 28.1923 16.9347 25.2731 0.354 28.8294L0 29.9351Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2028 6.10352e-05L1.85791 26.5713H7.98664L9.54446 20.9253H17.3706L18.9634 26.5713H25.1265L16.4357 6.10352e-05H10.2028ZM13.3539 5.8337L16.0201 16.0713H10.8263L13.3539 5.8337Z" fill="white"/>
            </svg>
        </a>
        <!-- <div class="header__title">
            Amway content CMS
        </div> -->
        <div class="header__menu" v-if="$route.name !== 'Auth'">
            <div class="header__user header__item" v-if="$route.name !== 'Auth'">
                <div class="header__item-text">{{User.username}}</div>
            </div>
            <router-link :to="{name: 'PromoList', params: {country: 'ru'}}" class="header__help header__item">
                <div class="header__icon">
                    <v-icon
                    color="#DADADA">
                        mdi-billboard
                    </v-icon>
                </div>
                 <div class="header__item-text">Промоакции</div>
            </router-link>
            <!-- <router-link :to="{name: 'Help'}" class="header__help header__item">
                <div class="header__icon">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1769 0.5H2.82315C2.36962 0.500615 2.00178 0.883697 2 1.35725V17.6427C2.00178 18.1163 2.36962 18.4994 2.82315 18.5H15.1769C15.6301 18.4988 15.9976 18.116 16 17.6427V1.35725C15.9976 0.883953 15.6301 0.501229 15.1769 0.5ZM14.3526 16.7855H3.64738V2.2145H14.3526V16.7855ZM12.7063 5.648C12.704 6.12173 12.3358 6.50464 11.8821 6.50525H6.1179C5.66448 6.50525 5.29691 6.12145 5.29691 5.648C5.29691 5.17455 5.66448 4.79075 6.1179 4.79075H11.8821C12.3337 4.79133 12.701 5.17084 12.7063 5.64237V5.648ZM12.7063 9.077C12.704 9.55073 12.3358 9.93364 11.8821 9.93425H6.1179C5.66448 9.93425 5.29691 9.55045 5.29691 9.077C5.29691 8.60355 5.66448 8.21975 6.1179 8.21975H11.8821C12.3337 8.22033 12.701 8.59984 12.7063 9.07137V9.077ZM12.7063 12.506C12.704 12.9796 12.3356 13.3621 11.8821 13.3621H6.1179C5.81769 13.3735 5.53551 13.2127 5.38217 12.943C5.22884 12.6733 5.22884 12.3376 5.38217 12.0679C5.53551 11.7981 5.81769 11.6374 6.1179 11.6487H11.8821C12.334 11.6487 12.7016 12.0286 12.7063 12.5004V12.506Z" fill="currentColor"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1769 0.5H2.82315C2.36962 0.500615 2.00178 0.883697 2 1.35725V17.6427C2.00178 18.1163 2.36962 18.4994 2.82315 18.5H15.1769C15.6301 18.4988 15.9976 18.116 16 17.6427V1.35725C15.9976 0.883953 15.6301 0.501229 15.1769 0.5ZM14.3526 16.7855H3.64738V2.2145H14.3526V16.7855ZM12.7063 5.648C12.704 6.12173 12.3358 6.50464 11.8821 6.50525H6.1179C5.66448 6.50525 5.29691 6.12145 5.29691 5.648C5.29691 5.17455 5.66448 4.79075 6.1179 4.79075H11.8821C12.3337 4.79133 12.701 5.17084 12.7063 5.64237V5.648ZM12.7063 9.077C12.704 9.55073 12.3358 9.93364 11.8821 9.93425H6.1179C5.66448 9.93425 5.29691 9.55045 5.29691 9.077C5.29691 8.60355 5.66448 8.21975 6.1179 8.21975H11.8821C12.3337 8.22033 12.701 8.59984 12.7063 9.07137V9.077ZM12.7063 12.506C12.704 12.9796 12.3356 13.3621 11.8821 13.3621H6.1179C5.81769 13.3735 5.53551 13.2127 5.38217 12.943C5.22884 12.6733 5.22884 12.3376 5.38217 12.0679C5.53551 11.7981 5.81769 11.6374 6.1179 11.6487H11.8821C12.334 11.6487 12.7016 12.0286 12.7063 12.5004V12.506Z" fill="currentColor"/>
                    </svg>
                </div>
                 <div class="header__item-text">Справочник</div>
            </router-link> -->
            <div class="header__logout header__item" @click="log_out()">
                <div class="header__icon">
                    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14 0.75C14.4142 0.75 14.75 1.08579 14.75 1.5V15.5C14.75 15.9142 14.4142 16.25 14 16.25H6V14.75H13.25V2.25H6V0.75H14ZM10.0156 9.03031L6.12648 12.9194L5.06582 11.8587L7.67458 9.24998H0V7.74998H7.67458L5.06582 5.14123L6.12648 4.08057L10.0156 7.96965C10.3085 8.26255 10.3085 8.73742 10.0156 9.03031Z" fill="currentColor"/>
                    </svg>
                </div>
                 <div class="header__item-text">Выйти</div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: "Header",
    computed: {
        ...mapGetters(['User'])
    },
    methods: {
        ...mapActions(['logout']),
        async log_out() {
            await this.logout()
            this.$router.push({name: "Auth"})
        }
    }
}
</script>

<style lang="scss" scoped>
    .header {
        padding: 16px 30px;
        background-color:#0A467E;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media screen and (max-width: 600px) {
            padding: 0
        }

        &__title {
            margin-left: 15px;
            font-family: "Source Sans Pro", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #FFFFFF;
        }

        &__logo {
            @media screen and (max-width: 600px) {
                position: relative;
                width: 47px;
                height: 47px;
                display: flex;
                justify-content: center;
                align-items: center;
                &::before {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 1px;
                    height: 100%;
                    display: block;
                    content: "";
                    background-color: #195489;
                }
                &::after {
                    position: absolute;
                    top: 0;
                    right: -1px;
                    width: 1px;
                    height: 100%;
                    display: block;
                    content: "";
                    background-color: #18375E;
                }
            }
        }

        &__menu {
            margin-left: auto;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
        }

        &__item {
            position: relative;
            padding: 0 16px;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-family: "Source Sans Pro", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #DADADA;
            transition: .3s;
            text-decoration: none;
            cursor: pointer;
            &:hover {
                color: #fff;
            }
            &.router-link-active {
                color: #fff;
            }
            &::before {
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                display: block;
                content: "";
                background-color: #195489;
            }
            &::after {
                position: absolute;
                top: 0;
                right: -1px;
                width: 1px;
                height: 100%;
                display: block;
                content: "";
                background-color: #18375E;
            }
            &:last-child {
                &::before, 
                &::after {
                    display: none;
                }
            }
            &-text {
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
        }

        &__icon {
            margin-right: 10px;
            @media screen and (max-width: 600px) {
                margin-right: 0;
            }
        }

        &__user {
            font-weight: normal;
            @media screen and (max-width: 600px) {
                display: none;
            }
        }
    }
</style>