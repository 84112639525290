<template >
  <div class="add-button" v-click-outside="hide">
    <div class="add-button__head" @click="open = true" v-if="!open">
      <div class="add-button__icon">
        <v-icon
        color="#0A467E">
            mdi-plus
        </v-icon>
      </div>
      <div class="add-button__title">
        Добавить проект
      </div>
    </div>
    <v-form @submit.prevent="addNewPage(name)">
      <v-text-field
        outlined
        append-icon="mdi-check"
        label="Название"
        :type="'text'"
        v-model="name"
        v-if="open"
        :rules="[Rules.notEmpty]"
        @click:append="addNewPage(name)"
        hide-details="true">
      </v-text-field>
      <v-btn type="submit" class="d-none"></v-btn>
    </v-form>
  </div>
</template>

<script>
import ClickOutside from "v-click-outside";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { },
  props: {
    props: Object,
  },
  data: () => ({
    name: "",
    open: false,
  }),
  directives: {
    ClickOutside: ClickOutside.directive,
  },
  computed: {
      ...mapGetters(['Rules'])
  },
  methods: {
    ...mapActions(["addPage"]),
    hide() {
      this.open = false;
    },
    async addNewPage(name) {
      if (!name) return;
      let form_data = new FormData();
      form_data.append("title", name);
      await this.addPage({body: form_data});
      this.hide();
      this.name = "";
    },
  },
};
</script>

<style scoped lang="scss">
.add-button {
    margin-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #0A467E;

    & .v-form {
      width: 100%;
    }

    &:hover {
        color: #002F58;
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }

    &__head {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__title {
        text-decoration-line: underline;
    }
}
</style>
