<template>
  <v-app>
    <div class="help-layout">
      <Header />
      <div class="content">
        <v-breadcrumbs  
        divider=">"
        :items="breadcrumbs">
          <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :href="item.href"
              :disabled="item.disabled"
              :to="item.to"
            >
              <svg v-if="item.text === 'home'" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 0L0 4.54545V10H10V4.54545L5 0ZM5 2.57273L8 5.3V8.18182H6V6.36353H4V8.18182H2V5.3L5 2.57273Z" fill="#858585"/>
              </svg>
              <span v-else>{{ item.text }}</span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-row 
          class="pt-8 my-0 help__row align-start"
        >
            <v-col 
            cols="12"
            sm="12"
            md="4"
            class="py-0 help__aside-wrap"
            >
              <div  class="help__aside">
                  <Menu />
              </div>
            </v-col>
             <v-col 
            cols="12"
            sm="12"
            md="8"
           class="py-0"
            >
            <router-view />
            </v-col>
        </v-row>
       
      </div>
      <Footer/>
    </div>  
  </v-app>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Menu from '../components/help/Menu.vue'
export default {
  components: { 
    Header,
    Footer,
    Menu, 
  },
  name: "helpLayout",
  data:() => ({
    breadcrumbs: [
      {
        text: "home",
        to: { name: "PromoList", params: { country: 'ru' } }
      },
      {
        text: "Справочник",
        to: { name: "Help" }
      }
    ]
  })
}
</script>

<style scoped lang="scss">
    .help-layout {
        width: 100%;
        min-height: 100vh;
        max-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        overflow-y: auto;
        padding-top: 75px;

        & .header {
          position: fixed;
          width: 100%;
          left: 0;
          z-index: 310;
        }

        & .header {
          top: 0;
        }
    }

    .help__aside {
      height: calc(100vh - 75px);
      overflow-y: auto;

      @media screen and (max-width: 960px) {
          height: auto;
      }
    }

    .help__aside-wrap {
      position: sticky;
      top: 0;
      @media screen and (max-width: 960px) {
        position: relative;
        z-index: 3;
      }
     
    }
</style>

<style lang="scss">
.help__aside {
  scrollbar-color: #0A467E transparent;
  scrollbar-width: thin;
  padding-bottom: 10px;
    &::-webkit-scrollbar {
        width: 3px;
        height: 1px;
        background-color: transparent;
    }

    // &::-webkit-scrollbar-thumb {
    //     background-color: #0A467E;
    //     border-radius: 5px;
    // }

    // &::-webkit-scrollbar-thumb:hover {
    //     background-color:  #002F58;
    // }
    &::-webkit-scrollbar-thumb { 
      background-color: rgba(0,250,0,0); 
    }

      &:hover {
        &::-webkit-scrollbar-thumb { 
            background-color: #0A467E;
            height: 10px; 
            border-radius: 5px;
            &:hover {
                background-color: #002F58; 
            }
        }
      }
}
</style>